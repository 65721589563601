import React, { useEffect, useState, useCallback } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import Layout from 'components/layout';
import SEO from 'components/seo';

const Box = ({ title, description, position }) => {
  let positionClass;
  if (position === 'left') {
    positionClass = 'bubbleLeft';
  } else if (position === 'center') {
    positionClass = 'bubbleCenter';
  } else {
    positionClass = 'bubbleRight';
  }
  return (
    <div
      className={`w-full bg-white relative py-8 px-12 bubble ${positionClass} isSm:py-4 isSm:px-8`}
    >
      <div>
        <div className="mb-4">
          <h5 className="text-primary normal-case font-semibold">{title}</h5>
        </div>
        <div className="mb-4">
          <p className="text-sm text-black">{description}</p>
        </div>
        <div>
          <a href="mailto:enquiries@maistro.com?subject=Demo Request">
            <h5 className="normal-case font-semibold text-orange">
              Request a demo
            </h5>
          </a>
        </div>
      </div>
    </div>
  );
};

const Features = ({
  title, type, content, typeSetter, img, alt,
}) => {
  const size = useBreakpoint();

  return (
    <div
      className="text-center w-80 mb-12 isMd:w-full"
      onClick={() => typeSetter(type)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          typeSetter(type);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="w-48 h-48 my-0 mx-auto mb-2">
        <img src={img} alt={alt} className="w-full cursor-pointer" />
      </div>
      <div className="mb-4">
        <h3 className="text-primary uppercase font-bold cursor-pointer">
          {title}
        </h3>
      </div>
      {size.md ? (
        <div className="pt-8">
          <Box
            title={content.title}
            description={content.description}
            position="center"
          />
        </div>
      ) : null}
    </div>
  );
};

const Services = () => {
  const size = useBreakpoint();
  const [type, setType] = useState('quick_quote');

  const wrapperSetType = useCallback(
    (val) => {
      setType(val);
    },
    [setType],
  );

  const contentLeft = {
    title:
      'Quickly and easily make new or repeat purchases from suppliers you trust',
    description: `When it comes to buying regular services, or sourcing something new but low-value, the process should be quick and pain-free.
    Maistro's Quick Quote workflow means anybody in your organisation can quickly and easily request quotations from existing and new suppliers using a specified
    turnaround - whether that is 2 hours or 2 days. With the option of holding a mini-tender or simply selecting a straight-up repurchase from an existing contact the
    flexibility and ease of use means less circumnavigation of the process by users, and more data captured from the transaction. All with compliancy and governance
    built-in.`,
  };
  const contentCenter = {
    title:
      'Run and manage a simple or complex RFx with ease-all from a single portal',
    description: `Whether you need to source an agency to handle your next product launch or find a healthcare provider to implement a global occupational health programme, the
    Maistro platform enables anybody, no matter their procurement experience, to source, manage and deliver their requirements quickly and efficiently.
    For those who lack experience purchasing services in a particular vertical, the Maistro Managed Service team are on hand to step in at any point in the process to
    ensure your project is delivered on time and in line with your own procurement practices and governance.`,
  };
  const contentRight = {
    title: 'Manage and settle supplier payments',
    description: `Using the Maistro platform to view, manage and settle supplier payments means the workload of your finance team is reduced dramatically. Turn thousands of
    monthly invoices into one with a consolidated summary of all your tail transactions directed through Maistro. They don't even need to have been sourced using the
    platform, a supplier portal with payment details that match PO’s and pre-authorised card payments automatically action payments for suppliers after invoice
    upload.`,
  };

  const [content, setContent] = useState({
    ...contentLeft,
    position: 'left',
  });

  useEffect(() => {
    if (type === 'quick_quote') {
      setContent({
        ...content,
        ...contentLeft,
        position: 'left',
      });
      content.position = 'left';
    } else if (type === 'full_rfx') {
      setContent({
        ...content,
        ...contentCenter,
        position: 'center',
      });
    } else {
      setContent({
        ...content,
        ...contentRight,
        position: 'right',
      });
    }
  }, [type]);

  return (
    <Layout>
      <SEO
        title="Tail Spend Services"
        description="Use the Maistro platform to easily setup a tender, quick quote or consolidate your supply base within the tail"
      />
      <div className="pt-8 pb-16">
        <div className="container">
          <div className="flex items-center justify-between isMd:justify-center isMd:flex-col">
            <div className="isMd:mb-12">
              <img
                src="/images/new_dash_fade.png"
                alt="new_dash_fade"
                className="w-full"
              />
            </div>
            <div className="w-1/2 isMd:w-full">
              <div className="text-right mb-8">
                <h1 className="text-primary">
                  Buy any service at speed (no experience necessary)
                </h1>
              </div>
              <div className="text-right mb-8">
                <p className="text-primary">
                  An easy to use platform that anyone can use. Maistro harnesses
                  powerful automation to guide buyers through the process with
                  procurement experts on hand to step in and help along the way.
                </p>
              </div>
              <div className="text-right">
                <p className="text-primary font-semibold">
                  Engage with our large network of pre-vetted suppliers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 bg-white">
        <div className="container">
          <div className="flex items-center justify-between isMd:justify-center isMd:flex-col">
            <div className="isMd:mb-16">
              <img
                src="/images/maistro_circle_graphic.png"
                alt="maistro_circle_graphic"
                className="w-full"
              />
            </div>
            <div className="pl-12 w-9/12 isMd:w-full isMd:pl-0">
              <div className="text-left">
                <h1 className="text-primary mb-2 text-heading">
                  Increase % spend under management
                </h1>
                <p className="text-primary font-medium">
                  Source to Pay through Maistro provides organisations with grip
                  and control over their lowest value procurement
                </p>
              </div>
              <div className="text-left mb-16 mt-16 isMd:mb-8 isMd:mt-8 pl-6 isMd:pl-0">
                <h1 className="text-primary mb-2 text-heading">
                  Increase & contract compliant spend
                </h1>
                <p className="text-primary font-medium">
                  Platform lets users buy from Maistro’s invite-only vetted
                  supply chain or incumbent contracted suppliers – or both
                </p>
              </div>
              <div className="text-left">
                <h1 className="text-primary mb-2 text-heading">
                  Improve workforce productivity
                </h1>
                <p className="text-primary font-medium">
                  Automation makes it faster, data makes it smarter. Maistro
                  digitisation and automation reduces time spent on
                  traditionally manual tasks by 80%+
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-16" style={{ backgroundColor: '#dbe4eb' }}>
        <div className="container">
          <div className="mb-4 text-center">
            <h1>A flexible approach to managing your tail</h1>
          </div>
          <div className="mb-16 text-center">
            <h4 className="normal-case text-2xl text-primary font-medium">
              Sourcing to settlement - no matter the value
            </h4>
          </div>
          <div className="flex justify-between items-center isMd:flex-col isLg:justify-center">
            <Features
              title="Quick Quote"
              type="quick_quote"
              img="/images/quick_quote_icon.png"
              alt="quick_quote_icon"
              content={contentLeft}
              typeSetter={wrapperSetType}
            />
            <Features
              title="Full RFx"
              type="full_rfx"
              img="/images/rFx_icon.png"
              alt="rFx_icon"
              content={contentCenter}
              typeSetter={wrapperSetType}
            />
            <Features
              title="Settlement"
              type="settlement"
              img="/images/settlement_icon.png"
              alt="settlement_icon"
              content={contentLeft}
              typeSetter={wrapperSetType}
            />
          </div>
          {size.md ? null : (
            <Box
              title={content.title}
              description={content.description}
              position={content.position}
            />
          )}
        </div>
      </div>

      <div className="pb-16" id="wheredoyouwanttostart">
        <div className="container">
          <div className="mb-16  mt-16 text-center">
            <h1>Where do you want to start?</h1>
          </div>
          <div className="flex justify-between items-center flex-wrap tab:justify-center">
            <div className="text-center w-80 mb-12">
              <div className="w-36 h-36 my-0 mx-auto mb-12">
                <img
                  src="/images/spend_analysis_icon.png"
                  alt="spend_analysis_icon"
                  className="w-full"
                />
              </div>
              <div className="mb-4">
                <h3 className="text-primary uppercase font-bold">
                  Spend Analysis
                </h3>
              </div>
              <div className="mb-6">
                <p className="text-primary font-normal">
                  We shine a light on your unmanaged spend, identifying key
                  areas to target for savings and consolidation
                </p>
              </div>
              <Link to="/analysis/">
                <h5 className="normal-case font-bold text-orange">more</h5>
              </Link>
            </div>

            <div className="text-center w-80 mb-12">
              <div className="w-32 h-40 my-0 mx-auto mb-8">
                <img
                  src="/images/poc_icon.png"
                  alt="poc_icon"
                  className="w-full"
                />
              </div>
              <div className="mb-4">
                <h3 className="text-primary uppercase font-bold">Trial</h3>
              </div>
              <div className="mb-6">
                <p className="text-primary font-normal">
                  Get started on the path to greater visibility and control with
                  a proof of concept project on the Maistro platform
                </p>
              </div>
              <a href="mailto:enquiries@maistro.com?subject=POC Enquiry">
                <h5 className="normal-case font-bold text-orange">more</h5>
              </a>
            </div>

            <div className="text-center w-80 mb-12">
              <div className="w-36 h-36 my-0 mx-auto mb-12">
                <img
                  src="/images/platform_demo_icon.png"
                  alt="platform_demo_icon"
                  className="w-full"
                />
              </div>
              <div className="mb-4">
                <h3 className="text-primary uppercase font-bold">Demo</h3>
              </div>
              <div className="mb-6">
                <p className="text-primary font-normal">
                  Take a 30 minute demo where our experts show you how we can
                  transform your digital buying experience
                </p>
              </div>
              <a href="mailto:enquiries@maistro.com?subject=Demo Request">
                <h5 className="normal-case font-bold text-orange">more</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Services;
